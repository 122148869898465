import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';

import WithAnimation from '@components/Common/WithAnimation';

import { THE_BRIDE } from '@/constants';
import { BG_DESKTOP_VIEW } from '@/constants/assets';

import useInvitation from '@hooks/useInvitation';

function LeftContent() {
  const isInvitation = useInvitation();

  return (
    <Box
      bgColor="bgPrimary"
      width="calc(100% - 500px)"
      height="100%"
      pos="fixed"
      bgImage={BG_DESKTOP_VIEW}
      bgSize="cover"
      padding="42px"
      borderRight="8px solid"
      borderRightColor="blackAlpha.400"
    >
      <WithAnimation>
        <Text letterSpacing="2px" fontSize="md" color="theme.bgSecondary" fontWeight="light">
          {isInvitation ? 'THE WEDDING OF' : 'WEDDING ANNOUNCEMENT'}
        </Text>
      </WithAnimation>
      <WithAnimation>
        <Heading
          margin="32px 0"
          fontWeight="normal"
          size="4xl"
          letterSpacing="2px"
          fontFamily="cursive"
          color="theme.bgSecondary"
          dangerouslySetInnerHTML={{ __html: THE_BRIDE }}
        />
      </WithAnimation>
      <WithAnimation>
        <Text
          fontWeight="light"
          fontStyle="italic"
          maxWidth="700px"
          color="theme.bgSecondary"
          letterSpacing="1px"
        >
          {`“The best thing to hold onto in life is each other”`}{' '}
          {/* <br />— Elizabeth Young */}
        </Text>
      </WithAnimation>
    </Box>
  );
}

export default LeftContent;
